import React from 'react';
import styled from '@react-pdf/styled-components';
import { View, Text, Image } from '@react-pdf/renderer';
import { formatNumberToPrettyString } from '@tymate/elise/utils';
import {
  icTrain,
  icShower,
  icPeople,
  icCar,
  icComputer,
  icCo2,
  icFuel,
  icEnergy,
  icWater,
  icThumb,
} from './images';

const Title = styled(Text)`
  font-size: 14px;
  margin: 0 auto;
  padding-bottom: 8px;
`;

const Equivalences = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Equivalence = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
  text-align: center;
`;

const EquivalenceText = styled(Text)`
  margin-top: 4px;
  font-size: 10px;
  font-family: 'Helvetica';
`;

const EnvironmentalBenefits = ({ equivalences }) => {
  return (
    <>
      <Title>Les bénéfices environnementaux et sociaux de votre action</Title>

      <Equivalences>
        <Equivalence>
          <Image
            src={icCo2}
            alt="icône de CO2"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.co2,
          )} kg de CO2`}</EquivalenceText>
          <EquivalenceText>=</EquivalenceText>
          <Image
            src={icTrain}
            alt="icône de train"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.kmTrain,
          )} km`}</EquivalenceText>
          <EquivalenceText>{`en train`}</EquivalenceText>
        </Equivalence>
        <Equivalence>
          <Image
            src={icFuel}
            alt="icône de pompe à essence"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.oil,
          )} litres de pétrole`}</EquivalenceText>
          <EquivalenceText>=</EquivalenceText>
          <Image
            src={icCar}
            alt="icône de voiture"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.kmCar,
          )} km en`}</EquivalenceText>
          <EquivalenceText>{`voiture essence`}</EquivalenceText>
        </Equivalence>
        <Equivalence>
          <Image
            src={icEnergy}
            alt="icône de prise électrique"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.energy,
          )} kWh d'énergie`}</EquivalenceText>
          <EquivalenceText>=</EquivalenceText>
          <Image
            src={icComputer}
            alt="icône d'ordinateur"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.pc,
          )} h d'utilisation`}</EquivalenceText>
          <EquivalenceText>{`d'un PC`}</EquivalenceText>
        </Equivalence>
        <Equivalence>
          <Image
            src={icWater}
            alt="icône d'eau"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.water,
          )} litres d'eau`}</EquivalenceText>
          <EquivalenceText>=</EquivalenceText>
          <Image
            src={icShower}
            alt="icône de douche"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.shower,
          )} douches `}</EquivalenceText>
          <EquivalenceText>{`de 5 min`}</EquivalenceText>
        </Equivalence>
        <Equivalence>
          <Image
            src={icThumb}
            alt="icône de pouce"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{`${formatNumberToPrettyString(
            equivalences?.job,
          )} heures de travail`}</EquivalenceText>
          <EquivalenceText>=</EquivalenceText>
          <Image
            src={icPeople}
            alt="icône de gens"
            style={{ width: 34, height: 34 }}
          />
          <EquivalenceText>{` ${formatNumberToPrettyString(
            equivalences?.etp,
          )} ETP mensuel`}</EquivalenceText>
        </Equivalence>
      </Equivalences>
    </>
  );
};

export default EnvironmentalBenefits;
