import { get, put } from 'api';

export const getReportings = ({ params }) =>
  put('/v1/extranet/reportings', params);

export const getReportingCSV = ({ params }) =>
  get('/v1/extranet/reportings/csv', { params });

export const getWasteRegisterCSV = ({ params }) =>
  put('v1/extranet/reportings/waste_register', params);

export const getCollectedResourcesCSV = ({ params }) =>
  put('/v1/extranet/reportings/collected_resources', params);
