import React, { useRef } from 'react';
import RawDatePicker from 'react-date-picker';
import { Stack } from '@tymate/margaret';
import { DatePickerStyle } from '@tymate/elise/ui/date-picker';
import { MdDateRange, MdHighlightOff } from 'react-icons/md';
import { ButtonReset } from '@tymate/margaret';
import { FormLabel } from '../ui/forms';

const DatePicker = ({
  name,
  label,
  value,
  onChange,
  disabled,
  clearIcon,
  maxDate,
  minDate,
}) => {
  const datePickerRef = useRef();
  return (
    <Stack
      direction="column"
      onClick={e => {
        // Prevent to open calendar on date clear.
        if (
          e?.target?.getAttribute('data-action') !== 'removeDate' &&
          !disabled
        ) {
          datePickerRef.current.openCalendar();
        }
      }}
    >
      <DatePickerStyle />
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <RawDatePicker
        ref={datePickerRef}
        locale="fr"
        name={name}
        value={value}
        onChange={onChange}
        clearIcon={
          clearIcon ? (
            <ButtonReset
              phantom
              data-action={value ? 'removeDate' : ''}
              style={{ opacity: Boolean(value) ? 1 : 0 }}
              onClick={() => onChange('')}
            >
              <MdHighlightOff style={{ pointerEvents: 'none' }} size="18" />
            </ButtonReset>
          ) : null
        }
        calendarIcon={<MdDateRange size={20} />}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
      />
    </Stack>
  );
};

export default DatePicker;
