import React, { useContext } from 'react';
import { Container, Card, Title } from '@tymate/elise/ui';
import ProfileForm from '@tymate/elise/components/ProfileForm';
import { AuthContext } from 'contexts';
import { updateMe } from 'api/auth';
import { pickBy, omit } from 'lodash';
import { useAppState } from 'hooks';

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);
  const { notify } = useAppState();

  const handleUpdateMe = async values => {
    try {
      const { data } = await updateMe(omit(pickBy(values), 'fakePassword'));
      notify('Profil mis à jour.');
      setUser(data);
    } catch (err) {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  return (
    <Container variant="main" size="narrow">
      <Title>Mon profil</Title>
      <Card>
        <ProfileForm user={user} onSubmit={handleUpdateMe} />
      </Card>
    </Container>
  );
};
export default Profile;
