import React from 'react';
import Modal from './Modal';
import { Buttons, Button } from '../ui';

const TermModal = ({ onRequestClose, term }) => {
  if (!Boolean(term)) {
    return null;
  }

  return (
    <Modal
      variant="medium"
      isOpen
      onRequestClose={onRequestClose}
      title={`Conditions générales ${
        term.kind === 'gtu' ? 'd’utilisation' : 'de vente'
      }`}
    >
      <div>{term.body}</div>
      <Buttons alignment="right">
        <Button variant="primary" onClick={onRequestClose}>
          Retour
        </Button>
      </Buttons>
    </Modal>
  );
};

export default TermModal;
