import React, { useState } from 'react';
import { Button } from '@tymate/elise';
import { Buttons } from '@tymate/elise/ui';
import Modal from '@tymate/elise/components/Modal';

const UserDeleteModal = ({ match, onDeleteUser }) => {
  const { userId } = match.params;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen title="Supprimer un utilisateur">
      <p style={{ textAlign: 'center' }}>
        Êtes-vous sûr de vouloir supprimer cet utilisateur ?
      </p>
      <Buttons alignment="center">
        <Button variant="phantom" to={`/utilisateurs`}>
          Annuler
        </Button>
        <Button
          variant="primary"
          isLoading={isLoading}
          onClick={() => {
            onDeleteUser(userId);
            setIsLoading(false);
          }}
        >
          Valider
        </Button>
      </Buttons>
    </Modal>
  );
};

export default UserDeleteModal;
