import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  HeaderLogo,
  HeaderLogoLink,
  MainNavItemLink,
  MobileHeader,
  MobileTrigger,
} from '@tymate/elise/ui/layout';
import logo from 'images/logo.png';
import UserDropdown from '@tymate/elise/components/UserDropdown';
import { getFranchiseCollectionPoints } from 'api/collectionPoints';
import { AuthContext, CollectionPointContext } from 'contexts';
import { MainNav, Stack, useMargaret, media } from '@tymate/margaret';
import { SelectSearchQuery } from '@tymate/elise';

const Placeholder = styled.span``;

const MainNavContent = styled(Stack)`
  flex: 1;

  ${media.desktop`
    padding-left: ${({ theme }) => theme.spacing(2)};
  `}
`;

const Header = ({ currentUserRole }) => {
  const { user, onReset } = useContext(AuthContext);
  const { collectionPoint, selectCollectionPoint } = useContext(
    CollectionPointContext,
  );
  const { isMobile } = useMargaret();

  return (
    <>
      {isMobile && (
        <>
          <MobileHeader>
            <Placeholder style={{ width: 60 }} />
            <HeaderLogoLink to="/">
              <HeaderLogo src={logo} alt="Élise" />
            </HeaderLogoLink>
            <UserDropdown user={user} onReset={onReset} />
          </MobileHeader>

          <MobileTrigger>
            <MainNav.Trigger />
          </MobileTrigger>
        </>
      )}

      <MainNav>
        <Stack
          size="full"
          paddingHorizontal={1}
          alignY="center"
          direction={{ default: 'column', tablet: 'row' }}
        >
          {!isMobile && (
            <MainNav.Header>
              <HeaderLogoLink to="/">
                <HeaderLogo src={logo} alt="Élise" />
              </HeaderLogoLink>
            </MainNav.Header>
          )}
          <MainNavContent>
            <MainNav.Items style={{ width: 'auto', padding: 0 }}>
              <MainNav.Item>
                <MainNavItemLink to="/" exact>
                  Mon bilan de recyclage
                </MainNavItemLink>
              </MainNav.Item>
              {['member', 'admin'].includes(currentUserRole) && (
                <>
                  <MainNav.Item>
                    <MainNavItemLink to="/documents">
                      Mes documents
                    </MainNavItemLink>
                  </MainNav.Item>
                  {/* <MainNav.Item>
                    <MainNavItemLink to="/support">Support</MainNavItemLink>
                  </MainNav.Item> */}
                </>
              )}
              {currentUserRole === 'admin' && (
                <MainNav.Item>
                  <MainNavItemLink to="/utilisateurs">
                    Utilisateurs
                  </MainNavItemLink>
                </MainNav.Item>
              )}
            </MainNav.Items>
          </MainNavContent>
          <Stack gutterSize={1}>
            {Boolean(collectionPoint?.name) && (
              <SelectSearchQuery
                queryKey={['franchiseCollectionPoints']}
                queryFn={getFranchiseCollectionPoints}
                searchKey="filter[search]"
                initialValueLabel={collectionPoint?.name}
                labelKey="name"
                labelValue="id"
                hasFixedWidth
                onChange={({ value }) => {
                  if (collectionPoint?.id !== value) {
                    selectCollectionPoint({ id: value });
                  }
                }}
              />
            )}

            {!isMobile && <UserDropdown user={user} onReset={onReset} />}
          </Stack>
        </Stack>
      </MainNav>
    </>
  );
};

export default Header;
