import React, { createContext, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@tymate/elise';
import { Buttons } from '@tymate/elise/ui';
import {
  MdSubdirectoryArrowRight,
  MdArrowDropDownCircle,
} from 'react-icons/md';
import { flatMap, includes, orderBy } from 'lodash';
import { Checkbox } from '@tymate/elise/components/Forms';
import { ButtonReset, Stack } from '@tymate/margaret';

const CollectionPointsTreeContext = createContext();

const ICONS_SIZE = 24;

const List = styled.div`
  list-style-type: none;
  padding-left: calc(${ICONS_SIZE}px + ${({ theme }) => theme.spacing(0.5)});
`;

const Icon = styled(Stack)`
  color: ${({ theme }) => theme.textLighten};
  transition: transform 150ms ease;

  svg {
    display: flex;
    align-items: center;
  }

  ${({ variant, theme }) =>
    variant === 'empty' &&
    `
      color: ${theme.separator}
    `};
`;

const SelectTextButton = styled.span`
  outline: none;
  transition: color 150ms ease;
  font-size: 0.75em;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  background-color: transparent;
  outline: none;
  margin-top: 0;
  text-decoration: underline;
  color: ${({ theme }) => theme.primary};

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
`;

const CollectionPointsTree = ({ collectionPoint }) => {
  const collectionPointName = collectionPoint?.name;
  const collectionPointId = collectionPoint?.id;
  const children = collectionPoint?.descendantCollectionPoints || [];
  const { selectedCollectionPointIds, setSelectedCollectionPointIds } =
    useContext(CollectionPointsTreeContext);

  const getDeepDescendantCollectionPointsIds = cp =>
    flatMap(cp?.descendantCollectionPoints || [], dcp => [
      dcp.id,
      ...getDeepDescendantCollectionPointsIds(dcp),
    ]);
  const deepDescendantCollectionPointsIds =
    getDeepDescendantCollectionPointsIds(collectionPoint);
  const hasSelectedAllDescencentCollectionPoints =
    deepDescendantCollectionPointsIds.filter(
      dcpid => !includes(selectedCollectionPointIds, dcpid),
    ).length === 0;

  const [isExpanded, setIsExpanded] = useState(
    includes(selectedCollectionPointIds, collectionPointId),
  );

  const isChecked = includes(selectedCollectionPointIds, collectionPointId);

  const handleToggleChildren = () => {
    if (hasSelectedAllDescencentCollectionPoints) {
      setSelectedCollectionPointIds(
        selectedCollectionPointIds.filter(
          id => !includes(deepDescendantCollectionPointsIds, id),
        ),
      );
      return;
    }

    setSelectedCollectionPointIds([
      ...new Set([
        ...selectedCollectionPointIds,
        ...deepDescendantCollectionPointsIds,
      ]),
    ]);
  };

  const handleToggleCollectionPoint = id => {
    if (includes(selectedCollectionPointIds, id)) {
      setSelectedCollectionPointIds(
        selectedCollectionPointIds.filter(scpid => scpid !== id),
      );
      return;
    }

    setSelectedCollectionPointIds([...selectedCollectionPointIds, id]);
  };

  useEffect(() => {
    if (isChecked) {
      setIsExpanded(true);
    }
  }, [isChecked]);

  if (!Boolean(collectionPoint)) {
    return null;
  }

  return (
    <List as="div">
      <Stack alignY="center" gutterSize={0.5} marginBottom={0.25}>
        {children.length > 0 ? (
          <ButtonReset onClick={() => setIsExpanded(!isExpanded)}>
            <Icon style={{ transform: isExpanded ? null : 'rotate(-90deg) ' }}>
              <MdArrowDropDownCircle size={ICONS_SIZE} />
            </Icon>
          </ButtonReset>
        ) : (
          <Icon variant="empty">
            <MdSubdirectoryArrowRight size={ICONS_SIZE} />
          </Icon>
        )}

        <Checkbox
          label={
            <Stack alignY="center">
              <span>{collectionPointName}</span>
              {isChecked && children.length > 0 && (
                <SelectTextButton
                  underlined
                  as="button"
                  onClick={handleToggleChildren}
                >
                  {hasSelectedAllDescencentCollectionPoints
                    ? 'Désélectionner tous les enfants'
                    : 'Sélectionner tous les enfants'}
                </SelectTextButton>
              )}
            </Stack>
          }
          name={`collectionPointIds.${collectionPointId}`}
          onChange={() => handleToggleCollectionPoint(collectionPointId)}
          checked={includes(selectedCollectionPointIds, collectionPointId)}
          noFullWidth
          iconSize={ICONS_SIZE}
        />
      </Stack>

      {isExpanded &&
        orderBy(
          collectionPoint?.descendantCollectionPoints || [],
          dcp => -1 * (dcp?.descendantCollectionPoints || []).length,
        ).map(child => (
          <CollectionPointsTree collectionPoint={child} key={child.id} />
        ))}
    </List>
  );
};

const CollectionPointsTreeWrapper = ({
  defaultSelectedSelectionPointIds,
  collectionPoint,
  onSubmit,
  onDismiss,
}) => {
  const [selectedCollectionPointIds, setSelectedCollectionPointIds] = useState(
    defaultSelectedSelectionPointIds,
  );

  return (
    <CollectionPointsTreeContext.Provider
      value={{ selectedCollectionPointIds, setSelectedCollectionPointIds }}
    >
      <CollectionPointsTree collectionPoint={collectionPoint} />

      <Buttons alignment="center" hasMarginTop>
        <Button type="button" onClick={onDismiss} variant="phantom">
          Annuler
        </Button>
        <Button
          variant="primary"
          onClick={() => onSubmit(selectedCollectionPointIds)}
        >
          Valider
        </Button>
      </Buttons>
    </CollectionPointsTreeContext.Provider>
  );
};

CollectionPointsTreeWrapper.defaultProps = {
  defaultSelectedSelectionPointIds: [],
};

export default CollectionPointsTreeWrapper;
