import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { MargaretProvider } from '@tymate/elise';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { theme, GlobalStyles } from '@tymate/elise/ui';
import AuthProvider from 'containers/AuthProvider';
import AppProvider from '@tymate/elise/providers/AppProvider';
import Callback from 'containers/Callback';
import ForgottenPassword from 'containers/ForgottenPassword';
import Auth from 'containers/Auth';
import 'sanitize.css';
import CollectionPointProvider from 'containers/CollectionPointProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { API_BASE, SENTRY_DSN, SENTRY_ENVIRONMENT } from './constants';
import { initSentry } from '@tymate/elise/utils/sentry';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
    },
  },
});

export const history = createBrowserHistory();

initSentry(SENTRY_DSN, SENTRY_ENVIRONMENT, API_BASE, history, 'elise-extranet');

const AppWrapper = () => (
  <CollectionPointProvider>
    <App />
  </CollectionPointProvider>
);

ReactDOM.render(
  <Suspense fallback={<div />}>
    <MargaretProvider theme={theme}>
      <QueryClientProvider client={client}>
        <AppProvider>
          <GlobalStyles />
          <AuthProvider>
            <Router history={history}>
              <Switch>
                <Route path="/callback/:kind?" component={Callback} />
                <Route path="/connexion" component={Auth} />
                <Route
                  path="/mot-de-passe-oublie"
                  component={ForgottenPassword}
                />
                <Route component={AppWrapper} />
              </Switch>
            </Router>
          </AuthProvider>
        </AppProvider>
      </QueryClientProvider>
    </MargaretProvider>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();
