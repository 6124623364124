import React from 'react';
import { Button } from '@tymate/elise';
import { Buttons } from '@tymate/elise/ui';
import { Formik } from 'formik';
import Modal from '@tymate/elise/components/Modal';
import { Form, SegmentedControlField } from '@tymate/elise/components/Forms';
import { history } from 'index';
import { useQuery } from 'react-query';
import { getUserCollectionPoint } from 'api/users';

const UserUpdateModal = ({ match, onEditUser, collectionPointId }) => {
  const { userId } = match.params;

  const roleOptions = [
    { value: 'member', label: 'Membre' },
    { value: 'guest', label: 'Invité' },
  ];

  const { data: userData } = useQuery(
    ['userCollectionPoint', userId, collectionPointId],
    () =>
      getUserCollectionPoint({
        userId: userId,
        collectionPointId: collectionPointId,
      }),
    {
      enabled: Boolean(userId) && Boolean(collectionPointId),
    },
  );
  const userCollectionPointRole = userData?.data?.collectionPointRole;

  return (
    <Modal
      isOpen
      variant="narrow"
      overflow="visible"
      title="Éditer un utilisateur"
      onRequestClose={() => history.push('/utilisateurs')}
    >
      <Formik
        initialValues={{ role: userCollectionPointRole || '', userId: userId }}
        enableReinitialize
        onSubmit={onEditUser}
      >
        {({ isSubmitting }) => (
          <Form>
            <SegmentedControlField
              name="role"
              label="Rôle"
              options={roleOptions}
              shouldHideErrorMessage
            />
            <Buttons alignment="center">
              <Button variant="phantom" to={`/utilisateurs`} type="button">
                Annuler
              </Button>
              <Button variant="primary" isLoading={isSubmitting}>
                Valider
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UserUpdateModal;
