import React, { useState } from 'react';
import * as Yup from 'yup';
import { TextField, Form } from '@tymate/elise/components/Forms';
import { Buttons, TextLink, ButtonReset } from '@tymate/elise/ui';
import { Button } from '@tymate/elise/components';
import { ERRORS } from '@tymate/elise/utils';
import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';

const ProfileForm = ({ user, onSubmit }) => {
  const [showEditPassword, setShowEditPassword] = useState(false);

  const profileFormSchema = Yup.object().shape({
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    email: Yup.string().email().required(ERRORS.REQUIRED),
    phoneNumber: Yup.string(),
    password: Yup.string().min(6, ERRORS.MIN_SIX),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], ERRORS.PASSWORDS_DO_NOT_MATCH)
      .when('password', {
        is: true,
        then: Yup.string().required(ERRORS.REQUIRED),
      }),
  });

  if (isEmpty(user)) {
    return null;
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={profileFormSchema}
      initialValues={{
        firstName: get(user, 'firstName', ''),
        lastName: get(user, 'lastName', ''),
        email: get(user, 'email', ''),
        phoneNumber: get(user, 'phoneNumber') || '',
        fakePassword: 'passpassword',
        password: '',
        passwordConfirmation: '',
      }}
    >
      {({ values, isSubmitting }) => (
        <Form autoComplete="off">
          <TextField name="firstName" label="Prénom" />
          <TextField name="lastName" label="Nom" />
          <TextField name="email" label="Email" disabled />
          <TextField name="phoneNumber" label="Téléphone" />
          {!showEditPassword && (
            <>
              <TextField
                name="fakePassword"
                label="Mot de passe"
                disabled
                type="password"
              />

              <TextLink
                as={ButtonReset}
                onClick={() => setShowEditPassword(true)}
                type="button"
              >
                Modifier mon mot de passe
              </TextLink>
            </>
          )}
          {showEditPassword && (
            <div style={{ marginTop: 16 }}>
              <TextField
                name="password"
                label="Nouveau mot de passe"
                type="password"
              />
              <TextField
                name="passwordConfirmation"
                label="Confirmer mon nouveau mot de passe"
                type="password"
              />
              <TextLink
                as={ButtonReset}
                onClick={() => setShowEditPassword(false)}
                type="button"
              >
                Annuler la modification de mot de passe
              </TextLink>
            </div>
          )}
          <Buttons hasTopMargin alignment="right">
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Enregistrer
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};
export default ProfileForm;
