import { get, put } from 'api';

export const getCollectionPointFolders = ({ collectionPointId, params }) =>
  get(`/v1/extranet/collection_points/${collectionPointId}/folders`, {
    params,
  });

export const getFolderDetails = ({ collectionPointId, folderId }) =>
  get(
    `/v1/extranet/collection_points/${collectionPointId}/folders/${folderId}`,
  );

export const editFolderContent = ({ collectionPointId, folderId }) =>
  put(
    `/v1/extranet/collection_points/${collectionPointId}/folders/${folderId}`,
  );

export const getFilesFolder = ({ folderId, params }) =>
  get(`/v1/extranet/folders/${folderId}/documents`, { params });

export const getDetailsFolderDocuments = ({ folderId, documentId }) =>
  get(`/v1/extranet/folders/${folderId}/documents/${documentId}`);
