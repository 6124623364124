import { get, post, destroy, put } from './';
import { CLIENT_ID } from '../constants';

export const getCollectionPointUsers = ({ collectionPointId }) =>
  get(`/v1/extranet/collection_points/${collectionPointId}/users`);

export const getUserCollectionPoint = ({ collectionPointId, userId }) =>
  get(`/v1/extranet/collection_points/${collectionPointId}/users/${userId}`);

export const inviteUser = (collectionPointId, payload) =>
  post(`/v1/extranet/collection_points/${collectionPointId}/users`, {
    ...payload,
    clientId: CLIENT_ID,
  });

export const removeUser = userId => destroy(`/v1/users/${userId}`);

export const removeCollectionPointUser = ({ collectionPointId, userId }) =>
  destroy(
    `/v1/extranet/collection_points/${collectionPointId}/users/${userId}`,
  );

export const editUser = (userId, collectionPointId, payload) =>
  put(`/v1/extranet/collection_points/${collectionPointId}/users/${userId}`, {
    ...payload,
  });
