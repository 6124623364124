import { useState, useEffect, useCallback, useReducer } from 'react';
import { pickBy, get, omit, snakeCase } from 'lodash';
import queryString from 'query-string';
import { useLocation, useSearchParam } from 'react-use';
import canParam from 'can-param';

export const getPageAndRestFromQuery = inputQuery => {
  if (typeof inputQuery !== 'string') {
    inputQuery = canParam(inputQuery);
  }
  const parsedQuery = queryString.parse(inputQuery);
  const page = parsedQuery['page[number]'] || 1;
  const query = `?${canParam(omit(parsedQuery, 'page[number]'))}`;

  return {
    page,
    query,
  };
};

const reducer = (state, action) => {
  const { page, query } = getPageAndRestFromQuery(action.payload.meta.query);
  const pagination = action.payload.meta.pagination;

  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        [query]: {
          ...get(state, [query]),
          statusesByPage: {
            ...get(state, [query, 'statusesByPage']),
            [page]: 'PENDING',
          },
        },
      };

    case 'SUCCESS':
      return {
        ...state,
        [query]: {
          ...get(state, [query]),
          pagination,
          dataByPage: {
            ...get(state, [query, 'dataByPage']),
            [page]: action.payload.result,
          },
          statusesByPage: {
            ...get(state, [query, 'statusesByPage']),
            [page]: 'DONE',
          },
        },
      };

    case 'ERROR':
      return {
        ...state,
        [query]: {
          ...get(state, [query]),
          statusesByPage: {
            ...get(state, [query, 'statusesByPage']),
            [page]: 'STALLED',
          },
        },
      };

    default:
      throw new Error();
  }
};

const selector = (state, rawQuery) => {
  const { page, query } = getPageAndRestFromQuery(rawQuery);
  const reducerChunk = get(state, [query], {});

  return {
    data: get(reducerChunk, ['dataByPage', page], []),
    loadingState: get(reducerChunk, ['statusesByPage', page], 'PENDING'),
    pagination: {
      page: Number(page),
      ...get(reducerChunk, 'pagination'),
    },
  };
};

const usePagination = ({
  requestFn,
  defaultParams,
  extraArguments,
  defaultSort = '',
  ...options
}) => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, {});
  const [params, setParams] = useState(defaultParams);
  const query = queryString.parse(location.search);
  const searchParamNames = options.searchParamNames || [];
  const page = useSearchParam('page');
  const q = useSearchParam('search');

  // const shouldDismissDefaultParams =
  //   keys(query).filter(key => !includes(['page', 'order', 'search'], key))
  //     .length > 0;

  const sort =
    (query.sort || defaultSort).charAt(0) === '-'
      ? (query.sort || defaultSort)
          .split('-')
          .map(snakeCase)
          .join(',-')
          .replace(',-', '-')
      : (query.sort || defaultSort).split(',').map(snakeCase).join(',');

  const searchChunk = searchParamNames.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: q,
    }),
    {},
  );

  const aggregateQuery = pickBy({
    'page[number]': page || 1,
    'page[size]': options?.perPage || 10,
    ...params,
    ...omit(query, ['page', 'sort', 'search']),
    ...searchChunk,
    sort,
  });

  const { data, loadingState, pagination } = selector(state, aggregateQuery);

  const request = useCallback(async () => {
    const query = canParam(aggregateQuery);
    let meta = { query };

    dispatch({ type: 'REQUEST', payload: { meta } });

    try {
      const { data: result, headers } = await requestFn({
        params: aggregateQuery,
        ...extraArguments,
      });

      meta.pagination = {
        totalPages: Number(headers.paginationTotalPages),
        totalCount: Number(headers.paginationTotalCount),
        pageSize: Number(headers.paginationPer),
      };

      dispatch({ type: 'SUCCESS', payload: { meta, result } });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: { meta } });
    }
    // eslint-disable-next-line
  }, [aggregateQuery]);

  useEffect(() => {
    request();
    // eslint-disable-next-line
  }, [canParam(aggregateQuery), q]);

  const setParam = param => setParams({ ...params, ...param });

  const skeletonLength = pagination
    ? pagination.page * pagination.perPage > pagination.total
      ? pagination.page * pagination.perPage - pagination.total
      : 5
    : 5;

  return {
    data,
    loadingState,
    pagination,
    setParam,
    setParams,
    skeletonLength,
    request,
    params,
  };
};

export default usePagination;
