import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '@tymate/elise/ui';
import { getCollectionPointFolders } from 'api/files';
import { DataTable, Pagination, Breadcrumb } from '@tymate/elise/components';
import { formatDate } from '@tymate/elise/utils';
import { parseISO } from 'date-fns';
import { MdFolder } from 'react-icons/md';
import { CollectionPointContext } from 'contexts';
import usePagination from '@tymate/elise/hooks/usePagination';

const UpdatedAt = styled.span`
  color: ${({ theme }) => theme.textLight};

  svg {
    color: ${({ theme }) => theme.textLight};
  }
`;

const FileName = styled.span`
  color: ${({ theme }) => theme.textLight};
  font-weight: 700;

  svg {
    color: ${({ theme }) => theme.textLighten};
  }
`;

const Folders = () => {
  const { collectionPoint } = useContext(CollectionPointContext);
  const collectionPointId = collectionPoint?.id ?? '';

  const { data, loadingState, request, pagination } = usePagination({
    requestFn: getCollectionPointFolders,
    extraArguments: { collectionPointId },
  });

  useEffect(() => {
    request();
    // eslint-disable-next-line
  }, [collectionPoint]);

  const headings = [
    { slug: 'name', label: 'Dossier', width: '100%' },
    {
      slug: 'updatedAt',
      label: 'Dernière modification',
    },
  ];

  const folders = (data ?? []).map(({ updatedAt, displayName, id }) => ({
    name: {
      render: () => (
        <FileName gutterSize={0.25}>
          <MdFolder size={24} />
          <span>{displayName}</span>
        </FileName>
      ),
    },
    updatedAt: {
      render: () => (
        <UpdatedAt>
          {formatDate(new Date(parseISO(updatedAt)), 'dd MMMM yyyy')}
        </UpdatedAt>
      ),
    },
    path: `/documents/${id}`,
  }));

  return (
    <>
      <Breadcrumb />
      <Container variant="main">
        <DataTable
          isSearchable={true}
          searchParamName="filter[display_name]"
          headings={headings}
          data={folders}
          loading={loadingState === 'PENDING'}
        />
      </Container>
      <Pagination {...pagination} />
    </>
  );
};

export default Folders;
