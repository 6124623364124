import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Folders from './Folders';
import Folder from './Folder';

const FoldersList = () => (
  <Switch>
    <Route path="/documents/:folderId" component={Folder} />
    <Route path="/documents" component={Folders} />
    <Redirect to="/documents" />
  </Switch>
);

export default FoldersList;
