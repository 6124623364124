import React, { useRef, useContext, useEffect } from 'react';
import { useAppState } from 'hooks';
import { Button, Pagination } from '@tymate/elise';
import { Container, Title, Icon } from '@tymate/elise/ui';
import DataTable from '@tymate/elise/components/DataTable';
import usePagination from '@tymate/elise/hooks/usePagination';
import {
  getCollectionPointUsers,
  inviteUser,
  removeCollectionPointUser,
  editUser,
} from 'api/users';
import {
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
} from '@tymate/elise/ui/popover';
import { Link } from 'react-router-dom';
import { MdPersonAdd, MdMoreVert } from 'react-icons/md';
import { get } from 'lodash';
import { FaUserCircle } from 'react-icons/fa';
import { Route } from 'react-router-dom';
import Dropdown from '@tymate/elise/components/Dropdown';
import { history } from 'index';
import { CollectionPointContext } from 'contexts';
import { Stack } from '@tymate/margaret';
import UserInvitationModal from 'components/UserInvitationModal';
import UserUpdateModal from 'components/UserUpdateModal';
import UserDeleteModal from 'components/UserDeleteModal';
import { getRole } from '@tymate/elise/utils';

const UsersList = () => {
  const { collectionPoint } = useContext(CollectionPointContext);
  const { notify } = useAppState();
  const dropdownRef = useRef();
  const collectionPointId = collectionPoint?.id ?? '';

  const { data: users, loadingState, request, pagination } = usePagination({
    requestFn: getCollectionPointUsers,
    extraArguments: { collectionPointId },
  });

  useEffect(() => {
    request();
    // eslint-disable-next-line
  }, [collectionPoint]);

  const handleDeleteUser = async userId => {
    try {
      await removeCollectionPointUser({ collectionPointId, userId });
      history.replace('/utilisateurs');
      notify('Utilisateur supprimé.');
      request();
    } catch (err) {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const handleEditUser = async values => {
    try {
      await editUser(values.userId, collectionPointId, {
        user: { role: values.role },
      });
      notify('Le rôle de l’utilisateur a bien été modifié.');
      history.push('/utilisateurs');
      request();
    } catch (err) {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const handleSubmitInviteUser = async (values, { setStatus }) => {
    try {
      await inviteUser(collectionPointId, { user: values });
      history.push('/utilisateurs');
      notify('Invitation envoyé');
      request();
    } catch (err) {
      setStatus(get(err, 'response.data.error'));
    }
  };

  const headings = [
    {
      slug: 'firstName',
      label: 'Nom du collaborateur',
    },
    {
      slug: 'email',
      label: 'Adresse email',
      cannotBeReordered: true,
    },
    {
      slug: 'role',
      label: 'Rôle',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      width: 200,
      textAlign: 'right',
    },
  ];

  const data = (users || []).map(
    ({ firstName, lastName, email, collectionPointRole, id }) => ({
      firstName: {
        render: () => (
          <Stack alignY="center">
            <Icon>
              <FaUserCircle />
            </Icon>
            {firstName} {lastName}
          </Stack>
        ),
      },
      email: { value: email },
      role: {
        value: collectionPointRole,
        render: () => getRole(collectionPointRole),
      },
      actions: {
        render: () => (
          <Dropdown
            ref={dropdownRef}
            trigger={
              <Icon style={{ justifyContent: 'flex-end' }} variant="isolated">
                <MdMoreVert />
              </Icon>
            }
          >
            <PopoverMenu alignment="right">
              <PopoverItem>
                <PopoverItemButton
                  as={Link}
                  to={`/utilisateurs/${id}/supprimer`}
                >
                  <span>Supprimer</span>
                </PopoverItemButton>
              </PopoverItem>
              <PopoverItem>
                <PopoverItemButton
                  as={Link}
                  to={`/utilisateurs/${id}/modifier`}
                >
                  <span>Modifier</span>
                </PopoverItemButton>
              </PopoverItem>
            </PopoverMenu>
          </Dropdown>
        ),
      },
    }),
  );

  return (
    <Container variant="main">
      <DataTable
        headings={headings}
        data={data}
        isSearchable={false}
        loading={loadingState === 'PENDING'}
        action={
          <Stack
            direction={{ default: 'column', tablet: 'row' }}
            alignY="center"
            size="full"
            alignX={{ default: 'flex-start', tablet: 'space-between' }}
            gutterSize={1}
          >
            <Title style={{ marginBottom: 0 }}>Utilisateurs</Title>
            <Button
              variant="outline"
              to="/utilisateurs/nouveau"
              icon={<MdPersonAdd size={24} />}
            >
              Ajouter un utilisateur
            </Button>
          </Stack>
        }
      />
      <Pagination {...pagination} />

      <Route
        path="/utilisateurs/nouveau"
        render={() => (
          <UserInvitationModal onInviteUser={handleSubmitInviteUser} />
        )}
      />

      <Route
        path="/utilisateurs/:userId/supprimer"
        render={props => (
          <UserDeleteModal onDeleteUser={handleDeleteUser} {...props} />
        )}
      />

      <Route
        path="/utilisateurs/:userId/modifier"
        render={props => (
          <UserUpdateModal
            collectionPointId={collectionPointId}
            onEditUser={handleEditUser}
            {...props}
          />
        )}
      />
    </Container>
  );
};

export default UsersList;
