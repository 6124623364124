import React, { useEffect, useState } from 'react';
import { CollectionPointContext } from 'contexts';
import {
  getFranchiseCollectionPointChildren,
  getFranchiseCollectionPoints,
} from 'api/collectionPoints';
import { useQuery } from 'react-query';
import { useAuth } from 'hooks';

const CollectionPointProvider = ({ children }) => {
  const [collectionPoint, setCollectionPoint] = useState(null);
  const { redirectCollectionPointId } = useAuth();

  const collectionPointId = collectionPoint?.id || redirectCollectionPointId;

  useQuery(
    ['firstFranchiseCollectionPoint'],
    () =>
      getFranchiseCollectionPoints({
        params: { 'page[size]': 1 },
      }),

    {
      onSuccess: data => {
        setCollectionPoint(data?.data?.[0]);
      },
      enabled: !Boolean(collectionPointId),
    },
  );

  const { refetch } = useQuery(
    ['franchiseCollectionPointChildren', collectionPointId],
    () =>
      getFranchiseCollectionPointChildren({
        collectionPointId,
      }),
    {
      onSuccess: data => {
        setCollectionPoint(data?.data);
      },
      enabled: Boolean(collectionPointId),
    },
  );

  useEffect(() => {
    if (collectionPointId) {
      refetch();
    }
  }, [collectionPoint, collectionPointId, refetch]);

  return (
    <CollectionPointContext.Provider
      value={{
        collectionPoint,
        selectCollectionPoint: setCollectionPoint,
      }}
    >
      {children}
    </CollectionPointContext.Provider>
  );
};

export default CollectionPointProvider;
