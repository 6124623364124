import { get } from './';

export const getFranchiseCollectionPoints = ({ params } = {}) =>
  get(`/v1/extranet/collection_points`, { params });

export const getFranchiseCollectionPointChildren = ({ collectionPointId }) =>
  get(`/v1/extranet/collection_points/${collectionPointId}`);

export const getFranchiseCollectionPointUser = ({
  collectionPointId,
  userId,
}) =>
  get(`/v1/extranet/collection_points/${collectionPointId}/users/${userId}`);
