import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Container, theme } from '@tymate/elise/ui';
import { ButtonReset, Stack } from '@tymate/margaret';
import { DataTable, Breadcrumb, Pagination } from '@tymate/elise/components';
import { getFolderDetails, getFilesFolder } from 'api/files';
import { MdInsertDriveFile, MdFileDownload, MdFolder } from 'react-icons/md';
import { formatDate } from '@tymate/elise/utils';
import { parseISO } from 'date-fns';
import { useAppState } from 'hooks';
import { CollectionPointContext } from 'contexts';
import usePagination from '@tymate/elise/hooks/usePagination';

const FileName = styled(Stack)`
  color: ${({ theme }) => theme.textLight};
  font-weight: 400;

  ${({ variant }) =>
    variant === 'folder' &&
    `
      font-weight: 600;
    `}

  svg {
    color: ${({ theme }) => theme.textLighten};
  }
`;

const IconButton = styled(ButtonReset)`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.separator};
  background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.primary};
    &:hover {
      color: ${theme.primaryLight};
    }
  }
`;

const FormatDate = styled.span`
  color: ${({ theme }) => theme.textLight};

  svg {
    color: ${({ theme }) => theme.textLight};
  }
`;

const Files = ({ match }) => {
  const { folderId } = match?.params;
  const { collectionPoint } = useContext(CollectionPointContext);
  const { setRouteName } = useAppState();
  const collectionPointId = collectionPoint?.id ?? '';

  const { data: folderData, loadingState } = usePagination({
    requestFn: getFolderDetails,
    extraArguments: { collectionPointId, folderId },
  });

  const { data, pagination, request } = usePagination({
    requestFn: getFilesFolder,
    extraArguments: { folderId },
  });

  useEffect(() => {
    request();
    // eslint-disable-next-line
  }, [collectionPoint]);

  const headings = [
    { slug: 'name', label: 'Fichier', width: '100%' },
    {
      slug: 'updatedAt',
      label: 'Dernière modification',
    },
    { slug: 'actions', label: null },
  ];

  useEffect(() => {
    setRouteName(folderData?.displayName, `/documents/${folderId}`);
    // eslint-disable-next-line
  }, [folderId]);

  const filesData = [{ displayName: '..' }, ...(data || [])].map(
    ({ createdAt, filename, displayName, url }, index) => ({
      name: {
        render: () => (
          <FileName
            gutterSize={0.25}
            variant={index === 0 && 'folder'}
            alignY="center"
          >
            {index === 0 ? (
              <MdFolder size={24} />
            ) : (
              <MdInsertDriveFile size={24} />
            )}

            <span>{filename || displayName}</span>
          </FileName>
        ),
      },
      createdAt: {
        render: () => (
          <FormatDate>
            {formatDate(new Date(parseISO(createdAt)), 'dd MMMM yyyy')}
          </FormatDate>
        ),
      },
      actions: {
        render: () =>
          index > 0 && (
            <IconButton
              as="a"
              href={url}
              target="/blank"
              rel="noopener, noreferrer"
            >
              <MdFileDownload />
            </IconButton>
          ),
      },
      path: index === 0 ? `/mes-documents` : null,
    }),
  );

  return (
    <>
      <Breadcrumb />
      <Container variant="main">
        <DataTable
          headings={headings}
          data={filesData}
          isSearchable={true}
          searchParamName="filter[filename]"
          loading={loadingState === 'PENDING'}
        />
        <Pagination {...pagination} />
      </Container>
    </>
  );
};

export default Files;
