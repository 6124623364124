import React, { memo } from 'react';

const IcBin = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      style={{ ...props.style, color: props.color, fontSize: props.size }}
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-148-255h595v842h-595z" />
        <path
          d="M149.5 80c0-67.931-55.069-123-123-123"
          stroke="#5AC8FA"
          strokeWidth={32}
        />
        <g>
          <path
            d="m21.668 32.735-.472-17.015-1.16.032.473 17.015 1.159-.032Zm8.367-16.983-1.16-.032-.472 17.015 1.159.032.473-17.015Zm-5.58 16.998h1.16V15.736h-1.16V32.75ZM14.182 14.128h1.26l1.05 19.592c.08 1.323 1.221 2.399 2.546 2.399h12.41a2.532 2.532 0 0 0 2.507-2.414l.684-19.577h1.25a.58.58 0 0 0 0-1.16h-5.92v-2.123a1.973 1.973 0 0 0-1.972-1.971h-5.923a1.974 1.974 0 0 0-1.972 1.971v2.123h-5.92a.58.58 0 0 0 0 1.16Zm7.08-3.283c0-.447.365-.812.812-.812h5.923c.448 0 .812.365.812.812v2.123h-7.547v-2.123Zm12.216 3.283-.681 19.534a1.379 1.379 0 0 1-1.349 1.297h-12.41c-.71 0-1.345-.6-1.387-1.304l-1.047-19.527h16.874ZM25.022 4.37h.004a.58.58 0 0 0 .58-.577l.02-3.211A.58.58 0 0 0 25.05 0h-.003a.58.58 0 0 0-.58.576l-.02 3.21a.58.58 0 0 0 .576.585ZM11.766 9.499a.577.577 0 0 0 .82.005.58.58 0 0 0 .006-.82l-2.3-2.33a.58.58 0 1 0-.826.815l2.3 2.33Zm26.129.305a.576.576 0 0 0 .407-.167L40.6 7.37a.58.58 0 0 0-.814-.826l-2.297 2.268a.58.58 0 0 0 .407.992Zm-6.089-4.121a.581.581 0 0 0 .756-.32l1.214-3.004a.58.58 0 1 0-1.075-.434l-1.214 3.003a.58.58 0 0 0 .32.755Zm-14.261-.238a.578.578 0 0 0 .76.307.58.58 0 0 0 .307-.76l-1.246-2.938a.58.58 0 0 0-1.068.453l1.247 2.938Zm23.38 32.02c-.665-1.238-2.282-1.62-3.67-.873l-5.64 2.883.527 1.032 5.65-2.888c.826-.444 1.754-.27 2.11.394.377.702.023 1.628-.788 2.064l-11.638 6.239-.488.122-10.851.293a.575.575 0 0 0-.14.02c-.065.019-1.592.465-3.373 2.482l.87.767c1.351-1.53 2.502-2.016 2.761-2.113l10.828-.291a.675.675 0 0 0 .124-.018l.62-.155a.584.584 0 0 0 .133-.051l11.702-6.273c1.375-.738 1.941-2.368 1.263-3.635Zm-21.653 1.687c1.436.378 3.99.464 4.117.468h4.284c.959 0 1.739.648 1.739 1.445 0 .796-.78 1.445-1.74 1.445h-5.925v1.16h5.926c1.598 0 2.899-1.17 2.899-2.605 0-1.437-1.3-2.605-2.9-2.605h-4.265c-.024-.001-2.529-.085-3.814-.422-.128-.04-3.165-.964-5.786.804l-5.02 4.61.787.854 4.917-4.526c2.143-1.417 4.73-.644 4.781-.628Z"
            fill="#EC732B"
            mask="url(#b)"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(IcBin);
