import React, { useEffect, useContext } from 'react';
import Header from 'components/Header';
import { Page, Content } from '@tymate/elise/ui/layout';
import { AuthContext, CollectionPointContext } from 'contexts';
import { history } from 'index';
import { Route, Switch, Redirect } from 'react-router-dom';
import HomeReporting from 'containers/HomeReporting.js';
// import Support from 'containers/Support';
import Users from 'containers/Users';
import Profile from 'containers/Profile.js';
import Folders from 'containers/Folders';
import 'typeface-quicksand';
import { useQuery } from 'react-query';
import { getFranchiseCollectionPointUser } from 'api/collectionPoints';
import useLocationBlocker from '@tymate/elise/hooks/useLocationBlocker';

const App = () => {
  const { accessToken, user } = useContext(AuthContext);
  const { collectionPoint } = useContext(CollectionPointContext);
  useLocationBlocker();

  const { data: collectionPointUser } = useQuery(
    ['collectionPointUser', user, collectionPoint],
    () =>
      getFranchiseCollectionPointUser({
        collectionPointId: collectionPoint?.id,
        userId: user?.id,
      }),
    { enabled: Boolean(user?.id && collectionPoint?.id) },
  );
  const collectionPointRole = collectionPointUser?.data?.collectionPointRole;

  const role = user?.role;
  const isAdminOrMember = ['member', 'admin'].includes(collectionPointRole);

  useEffect(() => {
    if (!accessToken) {
      history.push('/connexion');
    }
  }, [accessToken]);

  const hasUserAccess = isAdminOrMember || role === 'user';

  return (
    <Page>
      <Header currentUserRole={collectionPointRole} />

      <Content>
        <Switch>
          <Route path="/profil" component={Profile} />
          {/* {isAdminOrMember && <Route path="/support" component={Support} />} */}
          {isAdminOrMember && <Route path="/documents" component={Folders} />}
          {hasUserAccess && <Route path="/utilisateurs" component={Users} />}
          <Route path="/" component={HomeReporting} />
          <Redirect to="/" />
        </Switch>
      </Content>
    </Page>
  );
};

export default App;
