import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import styled, { css } from '@react-pdf/styled-components';
import { sum } from 'lodash';
import { IcBin } from 'components/pdf/icons';
import { formatNumberToPrettyString } from '@tymate/elise/utils';
import { theme } from '@tymate/elise/ui';

const Col = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;

const Chart = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Pie = styled(View)`
  flex: 1;
  position: relative;
  text-align: center;
`;

const Legends = styled(View)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  font-size: 9px;
`;

const Circle = styled(View)`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  margin-right: 4px;
`;

const Labels = styled(View)`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const Label = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

const Values = styled(View)`
  display: flex;
  flex-direction: column;
`;

const TextValue = styled(Text)`
  margin-bottom: 6px;
`;

const PieText = styled(View)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TotalWeight = styled(Text)`
  font-size: 24px;
  margin-top: 6px;
  margin-bottom: 8px;
  font-family: 'Helvetica';
`;

const Description = styled(Text)`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Helvetica';
`;

const Definition = styled(Text)`
  font-size: 8px;
  color: rgba(0, 0, 0, 0.6);
`;

const Percentage = styled(Text)`
  color: #eb732c;
  font-family: 'Helvetica';
`;

const DescriptionWithPercentage = ({ data, totalWeight }) => {
  const totalWeightWithoutDIB = sum(
    data
      .filter(({ label, value }) => label !== 'DIB' && value)
      .map(({ value }) => value),
  );

  const percentage = formatNumberToPrettyString(
    (totalWeightWithoutDIB / totalWeight) * 100,
  ).replace('.00', '');

  return (
    <Description>
      dont <Percentage>{percentage}%</Percentage> recyclés*
    </Description>
  );
};

const PieChart = ({ data = [], canvasUrl }) => {
  const totalWeight = sum(data.map(({ value }) => value).filter(Boolean));
  const hasDIB = data.some(({ label }) => label === 'DIB');

  return (
    <Chart>
      <Pie>
        {Boolean(canvasUrl) && <Image src={canvasUrl} />}
        <PieText>
          <IcBin />
          <TotalWeight>{`${formatNumberToPrettyString(
            totalWeight,
          )} kg`}</TotalWeight>
          <Col marginBottom={6}>
            <Description>de déchets collectés</Description>
            {hasDIB ? (
              <DescriptionWithPercentage
                data={data}
                totalWeight={totalWeight}
              />
            ) : (
              <Description>pour être recyclés*</Description>
            )}
          </Col>
          <Col>
            <Definition>*recyclage matière</Definition>
            <Definition>ou valorisation</Definition>
          </Col>
        </PieText>
      </Pie>

      <Legends>
        <Labels>
          {data.map(({ color, label }, index) => (
            <Label key={index}>
              <Circle color={color ?? theme.primary} />
              <Text>{label}</Text>
            </Label>
          ))}
        </Labels>

        <Values>
          {data.map(({ value }, index) => (
            <TextValue key={index}>{`${formatNumberToPrettyString(
              value,
            )} kg`}</TextValue>
          ))}
        </Values>
      </Legends>
    </Chart>
  );
};

export default PieChart;
