import React from 'react';
import { MargaretProvider as RawMargaretProvider } from '@tymate/margaret';
import { GlobalStyles, theme } from '../ui';

const MargaretProvider = ({ children }) => (
  <RawMargaretProvider theme={theme}>
    <GlobalStyles />
    {children}
  </RawMargaretProvider>
);

export default MargaretProvider;
