import React, { useContext, useEffect } from 'react';
import AuthLayout from '@tymate/elise/components/AuthLayout';
import LoginForm from '@tymate/elise/components/LoginForm';
import background from 'images/background-auth.png';
import logo from 'images/logo.png';
import { signIn } from 'api/auth';
import { AuthContext } from 'contexts';
import { history } from 'index';

const Auth = () => {
  const { onUpdate, accessToken } = useContext(AuthContext);

  const handleSignIn = async (values, { setStatus }) => {
    try {
      const { data } = await signIn(values);
      onUpdate(data);
    } catch (err) {
      setStatus(err?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (Boolean(accessToken)) {
      history.push('/');
    }
  }, [accessToken]);

  return (
    <AuthLayout backgroundImage={background} logo={logo}>
      <LoginForm onSubmit={handleSignIn}></LoginForm>
    </AuthLayout>
  );
};

export default Auth;
