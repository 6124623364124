import * as React from 'react';

const IcComputer = props => (
  <svg width={97} height={96} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{'25193CEA-ADF0-47BE-9FB6-FE0D85F6AE6E'}</title>
    <g transform="translate(.5)" fill="none" fillRule="evenodd">
      <rect fill="#007AFF" width={96} height={96} rx={48} />
      <g fillRule="nonzero">
        <path
          fill="#E6E6E6"
          d="M59.755 70.358H36.246l1.622-3.277 1.731-3.495h16.802l1.732 3.495z"
        />
        <path
          d="M66.519 72.255c0 1.047-.863 1.896-1.926 1.896H31.407a1.934 1.934 0 0 1-1.362-.555 1.88 1.88 0 0 1-.564-1.341c0-1.049.862-1.897 1.926-1.897h33.186c.531 0 1.013.211 1.362.554.35.345.564.818.564 1.343Z"
          fill="#CCC"
        />
        <path
          d="M66.519 72.255c0 1.047-.863 1.896-1.926 1.896H42.274a1.935 1.935 0 0 1-1.362-.555 1.879 1.879 0 0 1-.563-1.341c0-1.049.862-1.897 1.925-1.897h22.319c.531 0 1.013.211 1.362.554.35.345.564.818.564 1.343Z"
          fill="#E6E6E6"
        />
        <path
          d="M75.169 28.112v30.18c0 2.924-2.407 5.294-5.375 5.294H26.206c-2.967 0-5.374-2.37-5.374-5.294v-30.18c0-2.923 2.407-5.293 5.374-5.293h43.588c2.968 0 5.375 2.37 5.375 5.293Z"
          fill="#58C3E8"
        />
        <path
          d="M75.169 53.975v4.317c0 2.924-2.407 5.294-5.375 5.294H26.206c-2.967 0-5.374-2.37-5.374-5.294v-4.317h54.337Z"
          fill="#E6E6E6"
        />
        <path
          fill="#CCC"
          d="M58.133 67.081H37.868l1.731-3.495h16.802zM50.218 58.671c0 1.207-.993 2.185-2.218 2.185s-2.218-.978-2.218-2.185c0-1.206.993-2.184 2.218-2.184s2.218.978 2.218 2.184Z"
        />
        <path fill="#49BD90" d="M41.84 25.908h14.47V40.16H41.84z" />
        <path fill="#FFD061" d="m53.597 29.522-9.933 16.946H63.53z" />
        <path
          d="M49.99 43.928c0 4.162-3.426 7.535-7.651 7.535-4.226 0-7.652-3.373-7.652-7.535s3.426-7.536 7.652-7.536c4.225 0 7.65 3.374 7.65 7.536Z"
          fill="#FA70B2"
        />
        <path
          d="M69.794 22h-34.99a.826.826 0 0 0-.831.82c0 .452.372.818.832.818h34.989c2.505 0 4.543 2.007 4.543 4.474v25.044h-6.265a.826.826 0 0 0-.832.82c0 .452.373.818.832.818h6.265v3.499c0 2.467-2.038 4.474-4.543 4.474H26.206c-2.505 0-4.543-2.007-4.543-4.474v-3.499H65.3c.46 0 .832-.366.832-.819a.826.826 0 0 0-.832-.819H21.663V28.112c0-2.467 2.038-4.474 4.543-4.474h5.826c.46 0 .832-.366.832-.819a.825.825 0 0 0-.832-.819h-5.826C22.784 22 20 24.742 20 28.112v30.18c0 3.371 2.784 6.113 6.206 6.113h12.062l-2.542 5.133h-4.32c-1.52 0-2.756 1.219-2.756 2.716 0 1.498 1.236 2.716 2.757 2.716h33.186c1.52 0 2.757-1.218 2.757-2.716 0-1.497-1.236-2.716-2.757-2.716h-4.319l-2.541-5.133h12.06c3.423 0 6.207-2.742 6.207-6.112v-30.18C76 24.741 73.216 22 69.794 22Zm-4.107 50.254c0 .594-.49 1.078-1.094 1.078H31.407a1.087 1.087 0 0 1-1.094-1.078c0-.594.49-1.077 1.094-1.077h33.186c.603 0 1.094.483 1.094 1.077Zm-7.263-2.716H37.577l2.542-5.133h15.763l2.542 5.133Z"
          fill="#000"
        />
        <path
          d="M44.95 58.671c0 1.657 1.368 3.004 3.05 3.004 1.682 0 3.05-1.347 3.05-3.004 0-1.655-1.368-3.003-3.05-3.003-1.682 0-3.05 1.348-3.05 3.003Zm4.436 0c0 .753-.622 1.366-1.386 1.366a1.377 1.377 0 0 1-1.386-1.366c0-.752.622-1.365 1.386-1.365.764 0 1.386.613 1.386 1.365ZM56.31 25.09H41.84a.825.825 0 0 0-.832.818v9.769c-4.048.63-7.152 4.09-7.152 8.251 0 4.607 3.805 8.355 8.483 8.355a8.498 8.498 0 0 0 7.764-4.997h13.428a.834.834 0 0 0 .72-.41.807.807 0 0 0 0-.818l-7.11-12.13v-8.02a.825.825 0 0 0-.831-.819Zm-.831 1.637v4.364l-1.16-1.98a.835.835 0 0 0-.721-.409.834.834 0 0 0-.72.41l-4.987 8.507a8.514 8.514 0 0 0-5.22-2.037v-8.855H55.48Zm-13.14 23.917c-3.76 0-6.82-3.013-6.82-6.716 0-3.704 3.059-6.717 6.82-6.717 3.76 0 6.819 3.013 6.819 6.717 0 3.703-3.06 6.716-6.82 6.716Zm8.3-4.996a8.23 8.23 0 0 0-1.559-6.782l4.518-7.707 8.493 14.489H50.64Z"
          fill="#000"
        />
      </g>
    </g>
  </svg>
);

export default IcComputer;
