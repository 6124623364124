import React, { useState } from 'react';
import { AuthContext } from 'contexts';
import { getMe } from 'api/auth';
import { useDeepCompareEffect } from 'react-use';

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken'),
  );
  const [user, setUser] = useState();
  const [redirectCollectionPointId, setRedirectCollectionPointId] = useState();

  const handleUpdate = ({ accessToken, refreshToken }) => {
    localStorage.setItem('accessToken', accessToken);
    if (Boolean(refreshToken)) {
      localStorage.setItem('refreshToken', refreshToken);
    }
    setAccessToken(accessToken);
  };

  const handleReset = () => {
    setAccessToken();
    localStorage.clear();
  };

  const handleGetUser = async () => {
    try {
      const { data } = await getMe();
      setUser(data);
    } catch (err) {}
  };

  useDeepCompareEffect(() => {
    if (accessToken) {
      handleGetUser();
    }
  }, [{ accessToken }]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        setUser,
        handleGetUser,
        onReset: handleReset,
        onUpdate: handleUpdate,
        redirectCollectionPointId,
        setRedirectCollectionPointId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
