import styled from 'styled-components';
import { media } from '@tymate/elise/ui';
import { Stack } from '@tymate/margaret';

export const ReportingHeader = styled.h3`
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  font-weight: 700;
  text-align: center;
  font-family: Quicksand;
  padding: ${({ theme }) => theme.spacing(0.5)};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing()};
  `}
`;

export const ReportingCard = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 8px;
`;

export const ReportingContent = styled(Stack).attrs({
  alignY: 'center',
  alignX: 'space-between',
  size: 'full',
})`
  padding: ${({ theme }) => theme.spacing(3)};
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}
`;

export const ReportingItem = styled(Stack).attrs({
  direction: 'column',
})`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const Label = styled(Stack).attrs({
  direction: 'row',
  alignX: 'space-between',
  alignY: 'center',
})`
  margin-bottom: ${({ theme }) => theme.spacing(0.375)};
  text-transform: uppercase;
  align-items: 'center';
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

export const Values = styled(Stack).attrs({
  direction: 'column',
})`
  display: flex;
`;

export const TextValue = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing(0.375)};
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);

  span {
    text-shadow: 1px 1px 0 0 ${({ theme }) => theme.separator};
    color: #fff;
    font-size: 1.5rem;
  }
`;

export const Equivalence = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
  gutterSize: 1,
})`
  font-family: Quicksand;
  font-weight: 500;
  color: ${({ theme }) => theme.textLight};
  text-align: center;

  img {
    width: 64px;
    height: 64px;
  }
`;

export const Equivalences = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 8px;
`;

export const EquivalencesTitle = styled.h3`
  margin: 0;
  font-family: Quicksand;
  text-align: center;
  font-weight: 700;
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(0.5)};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing()};
  `}
`;

export const EquivalencesList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: repeat(5, 1fr);
  `}
`;
