import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useSearchParams } from '@tymate/elise/hooks';
import { useAuth } from 'hooks';
import { Redirect } from 'react-router-dom';

const TokenLogin = () => {
  const [{ accessToken: searchParamsAccessToken, collectionPoint }] =
    useSearchParams();
  const {
    onUpdate,
    accessToken: authAccessToken,
    setRedirectCollectionPointId,
  } = useAuth();

  useDeepCompareEffect(() => {
    if (Boolean(searchParamsAccessToken)) {
      onUpdate({ accessToken: searchParamsAccessToken });
    }
    if (Boolean(collectionPoint)) {
      setRedirectCollectionPointId(collectionPoint);
    }
  }, [{ searchParamsAccessToken }]);

  if (Boolean(authAccessToken)) {
    return <Redirect to="/" />;
  }

  return null;
};

export default TokenLogin;
