import React from 'react';
import { ResponsivePieCanvas } from '@nivo/pie';
import styled from 'styled-components';
import { sum } from 'lodash';
import { Stack } from '@tymate/margaret';
import { formatNumberToPrettyString } from '@tymate/elise/utils';
import IcBin from 'images/icons/IcBin';
import { theme } from '@tymate/elise/ui';

const Overlay = styled(Stack).attrs({
  alignX: 'center',
  size: 'full',
})`
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-30%, -30%);
`;

const PieText = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  alignX: 'center',
  gutterSize: 0.5,
})`
  width: 300px;
  height: 150px;
`;

const TotalWeight = styled.span`
  font-size: 3rem;
  margin-top: ${({ theme }) => theme.spacing(0.375)};
  font-family: Quicksand;
  font-weight: bold;
`;

const Description = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.textLight};
  font-weight: bold;
  font-family: Quicksand;
  line-height: 1.85rem;
`;

const Percentage = styled(Description)`
  color: ${({ theme }) => theme.primary};
  font-size: 1.75rem;
`;

const Definition = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.textLight};
  font-family: Quicksand;
  line-height: 1.25rem;
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const DescriptionWithPercentage = ({ data, totalWeight }) => {
  const totalWeightWithoutDIB = sum(
    data
      .filter(({ label, value }) => label !== 'DIB' && value)
      .map(({ value }) => value),
  );

  const percentage = formatNumberToPrettyString(
    (totalWeightWithoutDIB / totalWeight) * 100,
  ).replace('.00', '');

  return (
    <Stack direction="column" alignX="center">
      <Description>de déchets collectés</Description>
      <Description>
        dont <Percentage>{percentage}%</Percentage> recyclés*
      </Description>
    </Stack>
  );
};

const ReportingGraph = ({ data }) => {
  const totalWeight = sum(data.map(({ value }) => value));
  const hasDIB = data.some(({ label }) => label === 'DIB');

  const formattedData = data.map(weight => ({
    ...weight,
    value: weight.value,
  }));

  return (
    <div
      style={{
        height: 500,
        width: 500,
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <ResponsivePieCanvas
        data={formattedData}
        colors={colors =>
          Boolean(colors.color) ? colors.color : theme.primary
        }
        pixelRatio={2}
        startAngle={0}
        innerRadius={0.8}
        margin={{ top: 0, bottom: 0, right: 0, left: 0 }}
        padAngle={0.5}
        cornerRadius={2}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        animate={true}
      />
      <Overlay>
        <PieText>
          <IconWrapper>
            <IcBin size={96} />
          </IconWrapper>
          <TotalWeight>{`${formatNumberToPrettyString(
            totalWeight,
          )} kg`}</TotalWeight>
          {hasDIB ? (
            <DescriptionWithPercentage data={data} totalWeight={totalWeight} />
          ) : (
            <Stack direction="column" alignX="center">
              <Description>de déchets collectés</Description>
              <Description>pour être recyclés*</Description>
            </Stack>
          )}
          <Stack direction="column" alignX="center">
            <Definition>*recyclage matière</Definition>
            <Definition> ou valorisation</Definition>
          </Stack>
        </PieText>
      </Overlay>
    </div>
  );
};

export default ReportingGraph;
