import React from 'react';
import { Route } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import AuthLayout from '@tymate/elise/components/AuthLayout';
import SignUpForm from '@tymate/elise/components/SignUpForm';
import UpdatePasswordForm from '@tymate/elise/components/UpdatePasswordForm';
import background from '../images/background-auth.png';
import { useAuth, useAppState } from 'hooks';
import queryString from 'query-string';
import { get } from 'lodash';
import { acceptInvitation, updatePassword, getTerms } from 'api/auth';
import { history } from 'index';
import back from 'images/back.jpg';
import TokenLogin from './TokenLogin';
import logo from 'images/logo.png';
import { useMutation, useQuery } from 'react-query';

const Callbacks = ({ location, match }) => {
  const { notify } = useAppState();
  const { onUpdate, onReset } = useAuth();
  const params = queryString.parse(location.search);
  const invitationToken = get(params, 'invitation_token');
  const resetPasswordToken = get(params, 'reset_password_token');

  const { data: terms } = useQuery(['terms'], () => getTerms(), {
    enabled: Boolean(invitationToken),
  });

  const { mutateAsync: mutateInvitation } = useMutation(
    values => acceptInvitation(values),
    {
      onSuccess: ({ data }) => {
        onUpdate(data);
        history.push('/');
      },
    },
  );

  const { mutateAsync: mutatePassword } = useMutation(
    values => updatePassword(values),
    {
      onSuccess: ({ data }) => {
        onUpdate(data);
        notify('Mot de passe mis à jour.');
        history.push('/');
      },
      onError: () => {
        notify('Erreur lors de la mise à jour du mot de passe.');
      },
    },
  );

  const handleUpdatePassword = async values => {
    await mutatePassword({
      user: {
        password: values.password,
        resetPasswordToken,
      },
    });
  };

  const handleSubmitSignupForm = async values => {
    const gtuId = terms?.data?.filter(({ kind }) => kind === 'gtu')?.[0]?.id;
    const gtcId = terms?.data?.filter(({ kind }) => kind === 'gtc')?.[0]?.id;
    const user = {
      ...values,
      invitationToken: invitationToken,
      gtuId,
      gtcId,
    };

    await mutateInvitation({ user });
  };

  useEffectOnce(() => {
    if (match.params?.kind !== 'connexion') {
      onReset();
    }
  });

  return (
    <AuthLayout
      backgroundImage={background}
      sideImage={
        location.pathname.indexOf('/callback/invitation') === 0 && back
      }
      logo={logo}
    >
      <Route
        path="/callback/reset_password"
        render={() => <UpdatePasswordForm onSubmit={handleUpdatePassword} />}
      />

      <Route path="/callback/connexion" component={TokenLogin} />

      <Route
        path="/callback/invitations"
        render={props => (
          <SignUpForm
            app="extranet"
            terms={get(terms, 'data', [])}
            location={location}
            match={match}
            onSubmit={handleSubmitSignupForm}
          />
        )}
      />
    </AuthLayout>
  );
};

export default Callbacks;
