import React from 'react';
import { Button } from '@tymate/elise';
import { Buttons } from '@tymate/elise/ui';
import { Formik } from 'formik';
import Modal from '@tymate/elise/components/Modal';
import {
  Form,
  TextField,
  SegmentedControlField,
} from '@tymate/elise/components/Forms';
import * as Yup from 'yup';
import { ERRORS } from '@tymate/elise/utils';
import { history } from 'index';

const UserInvitationModal = ({ onInviteUser }) => {
  const roleOptions = [
    { value: 'member', label: 'Membre' },
    { value: 'guest', label: 'Invité' },
  ];

  return (
    <Modal
      variant="narrow"
      title="Inviter un collaborateur"
      isOpen
      onRequestClose={() => history.push('/utilisateurs')}
      overflow="visible"
      isScrollable
    >
      <Formik
        onSubmit={onInviteUser}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(ERRORS.REQUIRED),
          lastName: Yup.string().required(ERRORS.REQUIRED),
          email: Yup.string().required(ERRORS.REQUIRED).email(ERRORS.EMAIL),
          role: Yup.string().required(ERRORS.REQUIRED),
        })}
        initialValues={{
          role: 'guest',
          firstName: '',
          lastName: '',
          email: '',
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <TextField label="Nom" name="lastName" />
            <TextField label="Prenom" name="firstName" />
            <TextField label="Adresse email" name="email" />
            <SegmentedControlField
              name="role"
              label="Rôle"
              options={roleOptions}
              shouldHideErrorMessage
            />
            <Buttons alignment="center">
              <Button variant="phantom" type="button" to={`/utilisateurs`}>
                Annuler
              </Button>
              <Button variant="primary" isLoading={isSubmitting}>
                Valider
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UserInvitationModal;
